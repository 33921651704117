import { notFound } from 'next/navigation';
import qs from 'qs';
import {
  getRestApiURLforTenant,
  getSPSApiURLForTenant,
} from '@qb/frontend/utils/hosts/apiHost';
import { truncateToMaxStringLength } from '@qb/frontend/utils/truncateToMaxStringLength';
import { TenantAlias } from '@/shared/tenants-alias-map';
import { GetAppResponse } from '@/shared/types/controllers/AppControllerTypes';
import {
  GetSPPartPageSettingPayload,
  GetSPPartPageSettingResponse,
} from '@/shared/types/controllers/SPPartPageSettingControllerTypes';
import { SPSPartCategorySearchCategoriesResponse } from '@/shared/types/controllers/sps/SPSPartCategoryControllerTypes';
import { SPSPartRecordPartViewPayload } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
import {
  SearchPartCategoryAutocompleteResponse,
  SearchPartAutocompleteResponse,
  SearchPartsResponse,
  SearchPartsPayload,
} from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { getStandardHeaders } from '@/shared/utils/getStandardHeaders';
import { postRequestPromise } from './RequestPromise';

const SPS_TIMEOUT_MS = 10000;

export const get = async <ResponseBody>(
  url: string,
  params?: Record<string, unknown>,
) => {
  let urlWithParams = url;
  if (params) {
    urlWithParams += `?${qs.stringify(params)}`;
  }

  const response = await fetch(urlWithParams, {
    // Uncomment the `cache` option if testing it well
    // @see https://nextjs.org/docs/app/api-reference/functions/fetch#optionscache
    // cache: 'force-cache',
    signal: AbortSignal.timeout(SPS_TIMEOUT_MS),
    credentials: 'include',
    headers: getStandardHeaders({ library: 'fetch' }),
  });

  if (response.status === 404) {
    return notFound();
  }

  if (!response.ok) {
    throw {
      status: response.status,
      statusText: response.statusText,
    };
  }
  const data = await response.json();

  return data as Promise<ResponseBody>;
};

export const spsClientQueries = {
  app: {
    get: (hostName: string, tenantAlias: TenantAlias, appID: number) => ({
      queryKey: ['app', appID],
      queryFn: () =>
        get<GetAppResponse>(
          `${getRestApiURLforTenant(tenantAlias, hostName)}/app/${appID}`,
        ),
    }),
  },
  spPartPageSetting: {
    get: (
      hostName: string,
      tenantAlias: TenantAlias,
      payload: GetSPPartPageSettingPayload,
    ) => ({
      queryFn: async () =>
        get<GetSPPartPageSettingResponse>(
          `${getRestApiURLforTenant(tenantAlias, hostName)}/spPartPageSetting`,
          payload,
        ),
    }),
  },
  part: {
    queryKey: ['part'],
    recordPartView: (
      hostName: string,
      tenantAlias: TenantAlias,
      partID: number,
      utmSource?: string | null,
    ) => ({
      mutationFn: () =>
        postRequestPromise<void, SPSPartRecordPartViewPayload>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/part/${partID}/recordPartView`,
          { utmSource },
        ),
    }),
  },
  partCategory: {
    queryKey: ['partCategory'],
    searchCategories: (hostName: string, tenantAlias: TenantAlias) => ({
      queryKey: ['partCategory', 'searchCategories'],
      queryFn: () =>
        get<SPSPartCategorySearchCategoriesResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/partCategory/searchCategories`,
        ).then((res) => res.searchCategories),
    }),
  },
  search: {
    queryKey: ['search'],
    partCategoryAutocomplete: (
      hostName: string,
      tenantAlias: TenantAlias,
      query: string,
    ) => ({
      queryKey: ['search', 'partCategoryAutocomplete', query],
      queryFn: () =>
        get<SearchPartCategoryAutocompleteResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/search/partCategoryAutocomplete`,
          { query: truncateToMaxStringLength(query) },
        ),
    }),
    partAutocomplete: (
      hostName: string,
      tenantAlias: TenantAlias,
      query: string,
    ) => ({
      queryKey: ['search', 'partAutocomplete', query],
      queryFn: () =>
        get<SearchPartAutocompleteResponse>(
          `${getSPSApiURLForTenant(
            tenantAlias,
            hostName,
          )}/search/partAutocomplete`,
          { query: truncateToMaxStringLength(query) },
        ),
    }),
    searchParts: (
      hostName: string,
      tenantAlias: TenantAlias,
      searchDataPayload: SearchPartsPayload,
    ) => ({
      queryKey: ['search', 'searchParts', searchDataPayload],
      queryFn: async () => {
        return get<SearchPartsResponse>(
          `${getSPSApiURLForTenant(tenantAlias, hostName)}/search/searchParts`,
          searchDataPayload,
        );
      },
    }),
  },
};
