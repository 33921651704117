import { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { useQuery } from '@tanstack/react-query';
import currency from 'currency.js';
import { Stack, Typography, Button, Chip } from '@mui/material';
import { NumberInput } from '@qb/frontend/components/NumberInput/NumberInput';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { getPartMarketableStatus } from '@qb/frontend/utils/getPartMarketableStatus';
import { getHostFromWindow } from '@qb/frontend/utils/hosts/getHostFromWindow';
import { spsClientQueries } from '@qb/httpRequest/spsClientQueries';
import { tenantAliasObs } from '@/global/tenantStore/tenantStore';
import { useRequestPartPriceFromSPW } from '@/hooks/useRequestPartPriceFromSPW';
import { DEFAULT_VALUE, REQUEST_QUOTE_VALUE, CART_AND_BUY_VALUE, QUOTE_AND_CART_VALUE } from '@/shared/models/SPPartPageSettingConstants';
import { getConfigFromTenantAlias } from '@/shared/tenants-alias-map';
import { SearchPartsResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
const ERROR_MESSAGE_QTY_ABOVE_STOCK = 'The quantity you’ve selected exceeds the stock availability. We will process your order, deliver available items and inform you about the lead time.';
const CallToActionAddToCart = ({
  qty,
  stock,
  part
}: {
  part: SearchPartsResponse['parts'][0];
  qty: number;
  stock: number;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  useDebounce(() => setErrorMessage(qty > stock ? ERROR_MESSAGE_QTY_ABOVE_STOCK : ''), 200, [qty]);
  return <>
      <Button size="small" fullWidth variant="contained" color="primary" onClick={() => window?.salesPilotPostMessages?.addToCart({
      qty,
      partID: part.id
    })} data-sentry-element="Button" data-sentry-source-file="ShowcaseResultRowForm.tsx">
        Add to cart
      </Button>
      {!!errorMessage && <Typography variant="bodySmallError" sx={{
      mt: 1
    }}>
          {errorMessage}
        </Typography>}
    </>;
};
const CallToActionRequestQuoteButton = ({
  qty,
  part
}: {
  part: SearchPartsResponse['parts'][0];
  qty: number;
}) => {
  const tenantAlias = tenantAliasObs.get();
  const config = getConfigFromTenantAlias(tenantAlias);
  const {
    data,
    isSuccess
  } = useQuery({
    ...spsClientQueries.spPartPageSetting.get(getHostFromWindow(), tenantAlias, {
      companyID: config.tenantID
    })
  });
  const requestQuoteButton = <Button size="small" fullWidth variant="outlined" color="primary" onClick={() => {
    window?.salesPilotPostMessages?.createQuote({
      qty,
      partID: part.id
    });
  }} sx={{
    whiteSpace: 'nowrap'
  }}>
      Request Quote
    </Button>;
  if (!isSuccess) {
    return requestQuoteButton;
  }
  const buyNowButton = <Button size="small" fullWidth variant="contained" color="primary" onClick={() => {
    window?.salesPilotPostMessages?.buyNow({
      qty,
      partID: part.id
    });
  }}>
      Buy now
    </Button>;
  const addToCartButton = <Button size="small" fullWidth variant="contained" color="primary" onClick={() => {
    window?.salesPilotPostMessages?.addToCart({
      qty,
      partID: part.id
    });
  }}>
      Add to cart
    </Button>;
  const value = data?.spPartPageSetting?.value || DEFAULT_VALUE;
  if (value === REQUEST_QUOTE_VALUE) {
    return requestQuoteButton;
  }
  if (value === CART_AND_BUY_VALUE) {
    return <Stack direction="row" sx={{
      gap: 1
    }}>
        {addToCartButton}
        {buyNowButton}
      </Stack>;
  }
  if (value === QUOTE_AND_CART_VALUE) {
    return <Stack direction="row" sx={{
      gap: 1
    }}>
        {addToCartButton}
        {requestQuoteButton}
      </Stack>;
  }
  return null;
};
type ShowcaseResultRowFormProps = {
  part: SearchPartsResponse['parts'][0];
};
export const ShowcaseResultRowForm = ({
  part
}: ShowcaseResultRowFormProps) => {
  const {
    requestMultiplePartsPrices,
    getPartPrice
  } = useRequestPartPriceFromSPW();
  useEffect(() => {
    /**
     * Request the prices for the PDP part.
     */
    requestMultiplePartsPrices([part.id]);
  }, [part.id, requestMultiplePartsPrices]);
  const {
    price,
    originalPrice
  } = {
    price: part.price,
    originalPrice: part.originalPrice,
    ...getPartPrice(part.id)
  };
  const discountPercentage = getDiscountPercentage(originalPrice, price);
  const isDiscounted = !!discountPercentage;
  const combinedStock = part.onHandQty + part.manufacturerStock;
  const [qty, setQty] = useState(1);
  const renderSubTotal = () => {
    return <Stack sx={{
      alignItems: 'end',
      textAlign: 'right'
    }} data-sentry-element="Stack" data-sentry-component="renderSubTotal" data-sentry-source-file="ShowcaseResultRowForm.tsx">
        {!!originalPrice && isDiscounted && <Stack direction="row" spacing={0.5} alignItems="center" sx={{
        mb: 0.5
      }}>
            <Chip color="accent" size="small" sx={{
          color: 'text.primary',
          fontSize: 10,
          height: 14
        }} label={`${discountPercentage}% off`} />
            <Typography variant="bodySmallPrimary" sx={{
          fontSize: 10,
          textDecoration: 'line-through'
        }}>
              {formatCents(originalPrice)}
            </Typography>
          </Stack>}
        {!!price && <div>
            <Typography variant="h5" component="p">
              {currency(price, {
            fromCents: true
          }).multiply(qty).format()}
            </Typography>
            <Typography variant="bodySmall" sx={{
          color: 'grey.500',
          fontSize: 8
        }}>
              {formatCents(price)} each
            </Typography>
          </div>}
      </Stack>;
  };
  return <Stack direction="column" spacing={1} sx={{
    maxWidth: 230,
    ml: 'auto'
  }} data-sentry-element="Stack" data-sentry-component="ShowcaseResultRowForm" data-sentry-source-file="ShowcaseResultRowForm.tsx">
      <Stack direction="row" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="ShowcaseResultRowForm.tsx">
        <Stack direction="row" spacing={1} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="ShowcaseResultRowForm.tsx">
          <Typography variant="bodySmallPrimary" sx={{
          color: 'text.primary'
        }} data-sentry-element="Typography" data-sentry-source-file="ShowcaseResultRowForm.tsx">
            Qty:
          </Typography>
          <NumberInput onValueChange={setQty} initialValue={qty} buttonSx={{
          height: 24
        }} data-sentry-element="NumberInput" data-sentry-source-file="ShowcaseResultRowForm.tsx" />
        </Stack>
        {renderSubTotal()}
      </Stack>
      <div>
        {getPartMarketableStatus(part) === 'marketable' && <CallToActionAddToCart part={part} qty={qty} stock={combinedStock} />}
        {(getPartMarketableStatus(part) === 'hasStockNoPrice' || getPartMarketableStatus(part) === 'notMarketable') && <CallToActionRequestQuoteButton part={part} qty={qty} />}
      </div>
    </Stack>;
};