import { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, List, ListItem, Typography, Button, Stack, Collapse, Box } from '@mui/material';
import { FilterAccordion } from '@/global/pages/Search/_components/FilterAccordion';
import { useNavigationTransitionContext } from '@/global/pages/Search/_contexts/NavigationTransitionContext';
import { useUrlSearchParam } from '@/global/pages/Search/_hooks/useUrlSearchParam';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { isNullableOrEmptyArray } from '@/shared/types/util/TypeGuards';
const MAX_DEFAULT_OPTIONS = 5;
type FacetsProps = {
  facets: SearchDataResponse['facets'];
  areFiltersOpenedByDefault: boolean;
};
type FacetOptionProps = {
  facet: SearchDataResponse['facets'][0];
  facetOption: SearchDataResponse['facets'][0]['options'][0];
};
const FacetOption = ({
  facetOption,
  facet
}: FacetOptionProps) => {
  const {
    updateSearchParam,
    searchParamValues
  } = useUrlSearchParam(facet.camelCaseName);
  const initialChecked = searchParamValues.includes(facetOption.value);
  const [isChecked, setIsChecked] = useState(initialChecked);
  const {
    isPending
  } = useNavigationTransitionContext();

  // Need to sync the local checkbox state with the URL search param in case the filter gets removed from somewhere else
  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    setIsChecked(newChecked);
    const newFacetValues = newChecked ? [...searchParamValues, facetOption.value] : searchParamValues.filter(currentFacetValue => currentFacetValue !== facetOption.value);
    updateSearchParam(newFacetValues);
  };
  return <ListItem sx={{
    p: 0,
    pl: 1
  }} data-sentry-element="ListItem" data-sentry-component="FacetOption" data-sentry-source-file="Facets.tsx">
      <FormControlLabel sx={{
      ml: -0.125
    }} control={<Checkbox checked={isChecked} onChange={handleChange} disabled={isPending} size="small" sx={{
      p: 0.625,
      '& .MuiSvgIcon-root': {
        fontSize: 14
      }
    }} />} label={<Typography variant="bodySmall" sx={{
      color: 'text.primary'
    }}>
            {facetOption.value} ({facetOption.numParts})
          </Typography>} data-sentry-element="FormControlLabel" data-sentry-source-file="Facets.tsx" />
    </ListItem>;
};
type FacetGroupProps = {
  facet: SearchDataResponse['facets'][0];
  areFiltersOpenedByDefault: boolean;
};
const FacetGroup = ({
  facet,
  areFiltersOpenedByDefault
}: FacetGroupProps) => {
  const {
    searchParamValues
  } = useUrlSearchParam(facet.camelCaseName);
  const [areAllOptionsVisible, setAreAllOptionsVisible] = useState(false);
  const isSeeMoreButtonNeeded = facet.options.length > MAX_DEFAULT_OPTIONS;
  const options = isNullableOrEmptyArray(facet.options) ? [] : facet.options;
  const defaultOptions = options.slice(0, MAX_DEFAULT_OPTIONS);
  const extraOptions = options.slice(MAX_DEFAULT_OPTIONS);
  if (defaultOptions.length === 0) {
    return null;
  }
  return <FilterAccordion title={facet.name} defaultExpanded={areFiltersOpenedByDefault || searchParamValues.length > 0} data-sentry-element="FilterAccordion" data-sentry-component="FacetGroup" data-sentry-source-file="Facets.tsx">
      <List sx={{
      py: 0
    }} data-sentry-element="List" data-sentry-source-file="Facets.tsx">
        {defaultOptions.map(option => <FacetOption key={option.value} facetOption={option} facet={facet} />)}
        <Collapse in={areAllOptionsVisible} unmountOnExit data-sentry-element="Collapse" data-sentry-source-file="Facets.tsx">
          {extraOptions.map(option => <FacetOption key={option.value} facetOption={option} facet={facet} />)}
        </Collapse>
        {isSeeMoreButtonNeeded && <Stack justifyContent="end">
            <Button variant="text" size="small" onClick={() => setAreAllOptionsVisible(prevState => !prevState)} sx={{
          ml: 'auto',
          textDecoration: 'underline'
        }}>
              {areAllOptionsVisible ? 'See less' : 'Show more '}
            </Button>
          </Stack>}
      </List>
    </FilterAccordion>;
};
export const Facets = ({
  facets,
  areFiltersOpenedByDefault
}: FacetsProps) => <Box sx={{
  // TODO: recalculate this number after redesigning of results cards.
  maxHeight: 1470,
  overflow: 'scroll'
}} data-sentry-element="Box" data-sentry-component="Facets" data-sentry-source-file="Facets.tsx">
    {facets.map(facet => <FacetGroup key={facet.name} facet={facet} areFiltersOpenedByDefault={areFiltersOpenedByDefault} />)}
  </Box>;